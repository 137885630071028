// Dependencies
import { stripHtml } from '@/helpers/utils'

export const getStructuredFaqsList = (faqs) => {
  try {
    if (!faqs) return []
    if (faqs.constructor === Array) faqs = { faqs }

    const faqsList = []

    Object.keys(faqs).forEach((faqsGroup) => {
      faqsList.push(
        faqs[faqsGroup].map((faq) => ({
          '@type': 'Question',
          name: faq.title,
          acceptedAnswer: {
            '@type': 'Answer',
            text: stripHtml(faq.description),
          },
        })),
      )
    })
    return faqsList.flat()
  } catch (error) {
    return []
  }
}

export default {
  getStructuredFaqsList,
}
