<template>
  <section id="reviews" :class="['reviews', { 'reviews--vertical': vertical }]">
    <div class="container container--pv">
      <header>
        <p class="section-title">
          {{ $t('modules.reviews.title') }}
        </p>
        <p v-html="$t('modules.reviews.description')" />
      </header>

      <div class="reviews__source">
        <img
          alt="Google"
          decoding="async"
          fetchpriority="low"
          loading="lazy"
          src="/images/google-logo.svg"
        />

        <div class="rating">
          <b>4,9</b>

          <div class="rating__stars">
            <Icon name="star" />
            <Icon name="star" />
            <Icon name="star" />
            <Icon name="star" />
            <Icon name="star" />
          </div>

          <span>
            {{ $t('modules.reviews.count', { reviews: 108 }) }}
          </span>
        </div>
      </div>

      <div class="reviews__list">
        <div v-for="index of reviews" :key="`reqview_${index}`" class="noti-card review">
          <div class="noti-card__aside">
            <div class="review__avatar">
              <span>
                {{ $t(`modules.reviews.${index}.name`)[0] }}
              </span>
            </div>
          </div>
          <div class="noti-card__content">
            <p class="review__name">
              {{ $t(`modules.reviews.${index}.name`) }}
            </p>
            <p class="review__content">
              {{ $t(`modules.reviews.${index}.content`) }}
            </p>
          </div>
        </div>
      </div>

      <footer class="container-buttons">
        <Button
          href="https://www.google.com/maps/place/Astara+Store+-+Concesionario+Online+de+Coches+y+Motos/@40.5300511,-3.642321,17z/data=!3m1!5s0xd422f664cd1f739:0xe40d6d897767381f!4m8!3m7!1s0xd42289da1beba61:0x9586886d231ca40c!8m2!3d40.5300511!4d-3.642321!9m1!1b1!16s%2Fg%2F11g5_4xnr2?entry=ttu"
          rel="noopener,nofollow"
          size="medium"
          target="_blank"
          type-theme="secondary-light"
        >
          <span>
            {{ $t('modules.reviews.cta') }}
          </span>
        </Button>
      </footer>
    </div>
  </section>
</template>

<script>
// Components
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'

export default {
  name: 'ModuleReviews',
  components: {
    Button,
    Icon,
  },
  props: {
    vertical: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    reviews() {
      // Select N random reviews from all the available options
      const totalReviews = 12
      const reviewsToPick = 3
      const ids = []

      while (ids.length < reviewsToPick) {
        const random = Math.floor(Math.random() * totalReviews) + 1
        if (ids.indexOf(random) === -1) ids.push(random)
      }
      return ids
    },
  },
}
</script>

<style lang="scss" scoped>
p:not(.section-title) {
  margin: 0;

  & + p {
    margin-top: 1.4em;
  }
}

.reviews {
  .section-title {
    margin-bottom: 1rem;
  }

  &__source {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 2.5rem;
    padding-bottom: 2rem;

    .rating {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &__stars {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0 1rem 0 0.5rem;
        transform: translateY(-0.125rem);

        .icon {
          display: block;
          color: $c-support-01-400;
        }
      }
    }

    @include size-s {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .rating {
        margin-top: 0.5rem;
      }
    }
  }

  &__list {
    display: flex;
    gap: 2rem;
    padding-bottom: 2.5rem;

    .review {
      gap: 0.5rem;

      &__avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background-color: $c-neutral-600;
        font-weight: 500;
      }

      &__name {
        display: flex;
        align-items: center;
        height: 2rem;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.25rem;
      }

      &__content {
        margin-top: 0;
        font-size: 0.875rem;
        line-height: 1.25rem;

        @include size-s {
          margin-top: 0.625rem;
        }
      }
    }
  }

  @mixin verticalList {
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;

    .review + .review {
      padding-top: 2rem;
      border-top: 1px solid $c-neutral-500;
    }
  }

  &:not(.reviews--vertical) .reviews__list {
    @include size-l-up {
      align-items: stretch;
      justify-content: space-between;

      .review + .review {
        padding-left: 2rem;
        border-left: 1px solid $c-neutral-500;
      }
    }

    @include size-m {
      @include verticalList;
    }
  }

  &--vertical .reviews__list {
    @include verticalList;
  }
}
</style>
