<template>
  <component
    :is="banner?.external_url ? 'a' : banner?.path ? 'NuxtLink' : 'div'"
    :aria-label="banner.alt"
    :href="banner?.external_url ? banner.external_url : undefined"
    :rel="banner?.external_url ? 'nofollow' : undefined"
    :target="banner?.external_url ? '_blank' : '_self'"
    :to="banner.path ? localePath(banner.path) : undefined"
    class="banner"
    role="link"
  >
    <picture>
      <source
        v-if="banner.images.desktop"
        media="(min-width: 1024px)"
        :srcset="getImageUrl(banner.name, banner.images.desktop)"
      />
      <source
        v-if="banner.images.tablet"
        media="(min-width: 768px)"
        :srcset="getImageUrl(banner.name, banner.images.tablet)"
      />

      <img
        decoding="async"
        fetchpriority="low"
        loading="lazy"
        :src="getImageUrl(banner.name, banner.images.mobile)"
        :alt="banner.alt"
      />
    </picture>
  </component>
</template>

<script>
export default {
  name: 'HomeBanner',
  props: {
    locales: {
      type: Array,
      default: () => [],
      required: false,
    },
    banner: {
      type: Object,
      required: true,
      validator: (value) => {
        const isValidBanner = (banner) => {
          return (
            typeof banner.name === 'string' &&
            typeof banner.alt === 'string' &&
            banner.images &&
            typeof banner.images.desktop === 'string' &&
            typeof banner.images.mobile === 'string'
          )
        }

        return isValidBanner(value)
      },
    },
  },
  data() {
    return {
      available: false,
    }
  },
  methods: {
    getImageUrl(name, file) {
      return `/images/banners/${name}/${this.$i18n.locale}/${file}`
    },
  },
  mounted() {
    if (this.locales.length) {
      this.available = this.locales.includes(this.$i18n.locale)
    } else {
      this.available = true
    }
  },
}
</script>

<style lang="scss" scoped>
.banner {
  display: block;
  position: relative;
  border-radius: 0.5rem;

  img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
  }
}
</style>
