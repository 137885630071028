<template>
  <div class="modules-astara container container--pv">
    <header>
      <p class="section-title">
        {{ $t('modules.astara.title') }}
      </p>
    </header>

    <div class="container-columns container-columns--from-xl s-gap-40">
      <div class="container-columns__column">
        <div :class="['warranty-card', warrantyCardClass]">
          <div class="warranty-card__header">
            <div class="swiper-container">
              <div
                ref="astaraWarrantySlider"
                :class="[
                  // 'swiper',
                  'swiper--ready',
                ]"
              >
                <div class="swiper-wrapper">
                  <!-- <div
                        v-for="warrantyBenefit in warrantyBenefits"
                        :key="warrantyBenefit.description"
                        class="swiper-slide"
                      >
                        <img
                          :src="warrantyBenefit.img"
                          class="swiper-slide"
                          :alt="warrantyBenefit.alt"
                        />
                      </div> -->
                  <div class="swiper-slide">
                    <img
                      :alt="warrantyBenefits[0].alt"
                      :src="warrantyBenefits[0].img"
                      class="swiper-slide"
                      decoding="async"
                      fetchpriority="low"
                      loading="lazy"
                    />
                  </div>
                </div>
                <!-- <div class="swiper-button-prev">
                      <Icon name="arrow_back_ios_new" />
                    </div> -->
                <!-- <div class="swiper-button-next">
                      <Icon name="arrow_forward_ios" />
                    </div>
                    <div class="swiper-pagination" /> -->
              </div>
            </div>
          </div>
          <ul class="ul--clear warranty-card__body">
            <li
              v-for="warrantyBenefit in warrantyBenefits"
              :key="warrantyBenefit.description"
              class="container-flex container-flex--align-start container-flex--no-wrap s-gap-16"
            >
              <Icon :name="warrantyBenefit.icon" size="1.5rem" />
              <span class="f-size-18 f-size-500">
                {{ `${warrantyBenefit.description}${warrantyBenefit?.link?.url ? '.' : ''}` }}
                <NuxtLink v-if="warrantyBenefit?.link?.url" :to="warrantyBenefit.link.url">
                  <span>{{ warrantyBenefit.link?.label || warrantyBenefit.link.url }}</span>
                </NuxtLink>
                <a
                  v-else-if="warrantyBenefit?.link?.external_url"
                  :href="warrantyBenefit.link.external_url"
                  target="_blank"
                >
                  <span>{{ warrantyBenefit.link?.label || warrantyBenefit.link.url }}</span>
                </a>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="container-columns__column">
        <ModulesReviews vertical />
      </div>
    </div>
  </div>
</template>

<script>
// Dependencies
import { Swiper, Navigation, Pagination, Autoplay, EffectFade } from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/autoplay/autoplay.min.css'
import 'swiper/modules/effect-fade/effect-fade.min.css'

// Helpers & mixins
import checkViewportSize from '@/mixins/checkViewportSize'

// Components
import Icon from '@/components/Icon.vue'
import ModulesReviews from '@/components/modules/Reviews.vue'

export default {
  name: 'ModulesAstara',
  mixins: [checkViewportSize],
  components: {
    Icon,
    ModulesReviews,
  },
  props: {
    warrantyCardClass: {
      type: String,
      required: false,
      default: 'c-background-primary',
    },
  },
  data() {
    return {
      astaraWarrantySliderRef: undefined,
      warrantyBenefits: [],
    }
  },
  methods: {
    setupSlider() {
      const SWIPER_CONFIG = {
        lazy: true,
        lazyPreloadPrevNext: 1,
        loop: true,
        preventClicks: true,
        preventInteractionOnTransition: true,
        slidesPerView: 1,
        spaceBetween: 0,
        speed: 600,
        threshold: 0.5,
        // effect: 'fade',
        // fadeEffect: {
        //   crossFade: true,
        // },
        autoplay: {
          delay: 5000,
          disableOnInteraction: true,
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: false,
          dynamicBullets: true,
          dynamicMainBullets: 1,
        },
        modules: [Navigation, Pagination, Autoplay, EffectFade],
        breakpoints: {
          // When window width is >= X px
          1280: {
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
          },
        },
      }

      this.astaraWarrantySliderRef = new Swiper(this.$refs.astaraWarrantySlider, SWIPER_CONFIG)
    },
  },
  created() {
    this.warrantyBenefits = [
      {
        img: '/images/modules/astara/awb_1.webp',
        icon: 'workspace_premium',
        description: this.$t('modules.astara.benefits.1.description'),
        alt: this.$t('modules.astara.benefits.1.alt'),
      },
      {
        img: '/images/modules/astara/awb_1.webp',
        icon: 'verified_user',
        description: this.$t('modules.astara.benefits.2.description'),
        alt: this.$t('modules.astara.benefits.2.alt'),
      },
      {
        img: '/images/modules/astara/awb_1.webp',
        icon: 'assignment_return',
        description: this.$t('modules.astara.benefits.3.description'),
        alt: this.$t('modules.astara.benefits.3.alt'),
      },
      {
        img: '/images/modules/astara/awb_1.webp',
        icon: 'astara_logo_reduced',
        description: this.$t('modules.astara.benefits.4.description'),
        alt: this.$t('modules.astara.benefits.4.alt'),
        link: {
          external_url: 'https://astara.com/es/home',
          label: this.$t('modules.astara.benefits.4.link_label'),
        },
      },
    ]
  },
  mounted() {
    this.$nextTick(() => {
      this.setupSlider()
    })
  },
}
</script>

<style lang="scss" scoped>
.modules-astara {
  .swiper-button-prev,
  .swiper-button-next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    transition: opacity 0.3s ease;
    border: 2px solid $c-white;
    border-radius: 1.25rem;
    background-color: $c-primary-900;

    &::after {
      display: none;
    }

    .icon {
      color: $c-white;
      pointer-events: none;
    }

    &.swiper-button-disabled {
      opacity: 0;
    }

    > * {
      pointer-events: none;
    }

    @include size-m {
      display: none;
    }
  }

  :deep(.swiper-pagination) {
    position: relative !important;
    top: -1rem !important;
    right: unset !important;
    bottom: unset !important;
    left: unset !important;
    margin: 0 auto !important;
    padding: 1.25rem 0;
    transform: translateY(1rem) !important;

    @include size-xl-up {
      display: none;
    }

    .swiper-pagination-bullet {
      width: 0.75rem !important;
      height: 0.75rem !important;

      &:not(.swiper-pagination-bullet-active) {
        opacity: 1 !important;
        background: $c-neutral-900 !important;
      }
    }
  }

  @include size-xl-up {
    > .container > .container-columns > .container-columns__column {
      width: 33.75rem;
      max-width: 100%;
    }
  }

  @include size-xxl-up {
    .container-columns__column:nth-child(2) {
      padding-left: 6.25rem;
    }
  }

  .warranty-card {
    overflow: hidden;
    border-radius: 0.5rem;
    box-shadow: $c-elevation-2;

    @include size-l {
      margin-bottom: 2.5rem;
    }

    &__header img {
      aspect-ratio: 16 / 9;
      object-fit: cover;
      object-position: center;
    }

    &__body {
      padding: 1.25rem 1.25rem 2.5rem;

      @include size-xl-up {
        padding-top: 2.5rem;
      }

      li {
        &:not(:last-child) {
          padding-bottom: 1.25rem;
        }

        & + li {
          padding-top: 1.25rem;
          border-top: 0.0625rem solid $c-primary-050;
        }
      }
    }
  }

  :deep(#reviews) .container {
    padding: 0;

    .section-title {
      font-size: 1.5rem;
      line-height: 1.75rem;
    }

    .reviews__list {
      padding-bottom: 0;
      gap: 1.25rem;

      .review + .review {
        padding-top: 1.25rem;
      }
    }

    .button {
      display: none;
    }
  }
}
</style>
