<template>
  <section v-if="articles?.length || loading" class="related-articles">
    <div class="container container--pv">
      <header>
        <slot name="header">
          <p class="section-title">
            {{ title || $t('modules.related_articles.title') }}
          </p>
        </slot>
      </header>

      <Loader v-if="loading" loading theme="dark" />
      <div v-else ref="slider" class="swiper swiper--default-style">
        <div class="swiper-wrapper">
          <div v-for="article in articles" :key="article.id" class="swiper-slide">
            <BlogCard
              v-if="cardComponent === 'BlogCard'"
              :image="article.data?.thumbnail?.url"
              :title="article.data?.title[0].text"
              :date="article.last_publication_date"
              :summary="article.data?.summary[0]?.text"
              :category="article.data?.category || article"
              :url="article.url"
              :tag-link="`/news/${article.data?.category?.uid}`"
              :show-tag="!hideCategory"
              large
            />
            <HomeBlogCategoryCard
              v-else-if="cardComponent === 'HomeBlogCategoryCard'"
              :article="article"
              large
            />
          </div>
        </div>

        <div class="swiper-pagination" />
      </div>

      <footer v-if="$slots?.footer" class="s-pt-def">
        <slot name="footer"></slot>
      </footer>
    </div>
  </section>
</template>

<script>
// Dependencies
import { Swiper, Pagination } from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'

// Components
import BlogCard from '@/components/blog/Card.vue'
import HomeBlogCategoryCard from '@/components/home/BlogCategoryCard.vue'
import Loader from '@/components/Loader.vue'

export default {
  name: 'ModulesRelatedArticles',
  components: {
    BlogCard,
    HomeBlogCategoryCard,
    Loader,
  },
  props: {
    title: {
      type: [String, undefined],
      required: false,
      default: undefined,
    },
    articles: {
      type: Array,
      required: true,
    },
    hideCategory: {
      type: Boolean,
      required: false,
      default: false,
    },
    cardComponent: {
      type: String,
      required: false,
      validate: (value) => ['BlogCard', 'HomeBlogCategoryCard'].includes(value),
      default: 'BlogCard',
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      sliderObject: null,
    }
  },
  methods: {
    setupSlider() {
      this.sliderObject = new Swiper(this.$refs.slider, ARTICLES_SWIPER_CONFIG)
    },
  },
  watch: {
    loading: {
      handler(newVal, _) {
        if (!newVal) {
          this.$nextTick(() => {
            this.setupSlider()
          })
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.loading) {
        this.setupSlider()
      }
    })
  },
}

const ARTICLES_SWIPER_CONFIG = {
  modules: [Pagination],
  loop: false,
  speed: 600,
  slidesPerView: 1,
  spaceBetween: 20,
  preventClicks: true,
  preventInteractionOnTransition: true,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: false,
    dynamicBullets: true,
    dynamicMainBullets: 1,
  },
  breakpoints: {
    // When window width is >= X px
    768: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 24,
      allowTouchMove: false,
      preventInteractionOnTransition: false,
      longSwipes: false,
    },
    1280: {
      slidesPerView: 3,
      spaceBetween: 32,
      allowTouchMove: false,
      preventInteractionOnTransition: false,
      longSwipes: false,
    },
    1440: {
      slidesPerView: 3,
      spaceBetween: 40,
      allowTouchMove: false,
      preventInteractionOnTransition: false,
      longSwipes: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.related-articles {
  overflow: hidden;

  .swiper {
    overflow: visible;

    .swiper-wrapper {
      align-items: stretch;
    }

    .swiper-slide {
      height: auto;
    }

    @include size-l-up {
      .swiper-navigation {
        display: none;
      }
    }
  }
}
</style>
