<template>
  <div
    :class="[
      'card',
      'has-lazy-background',
      {
        'card--large': large,
        'card--clickable-tag': (articleList || nav) && showTag,
      },
    ]"
  >
    <picture class="has-lazy-background__background s-br-12">
      <img :alt="title" decoding="async" fetchpriority="low" loading="lazy" :src="image" />
    </picture>

    <div class="card__overlay" />

    <NuxtLink v-if="url" class="card__link" :to="localePath(url)" />

    <Tag
      v-if="showTag && !categoryTag"
      :label="`#${category?.data?.categoryname}`"
      :theme="large ? 'base-light' : 'primary'"
      :to="localePath(tagLink)"
      class="tag tag--top"
      small
    />

    <div class="card__content">
      <p v-if="articleList" class="date f-size-10 f-700 s-m-0">
        {{ formatDate(date) }}
      </p>

      <p
        :class="[
          'f-500',
          's-m-0',
          'title',
          {
            'f-size-18': nav,
            'f-size-24 f-size-24--responsive': !nav,
          },
        ]"
      >
        {{ title }}
      </p>

      <p v-if="nav" class="date f-size-10 f-700 s-m-0">
        {{ formatDate(date) }}
      </p>

      <Tag
        v-if="categoryTag"
        :label="`#${category.data.categoryname}`"
        class="tag"
        small
        theme="base-light"
      />

      <p v-if="summary" class="summary f-size-14 s-m-0">{{ summary }}</p>

      <p v-if="large" class="date f-500 f-size-14 s-m-0">
        {{ large ? formatDateAlt(date) : formatDate(date) }}
      </p>
    </div>
  </div>
</template>

<script>
// Helpers & mixins
import { formatDate, formatDateAlt } from '@/helpers/datetime'

// Components
import Tag from '@/components/Tag.vue'

export default {
  name: 'BlogCard',
  components: {
    Tag,
  },
  methods: {
    formatDate(date) {
      return formatDate(date)
    },
    formatDateAlt(date) {
      return formatDateAlt(date)
    },
  },
  props: {
    image: {
      type: String,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    date: {
      type: String,
      required: false,
      default: null,
    },
    summary: {
      type: String,
      required: false,
      default: null,
    },
    category: {
      type: Object,
      required: false,
      default: null,
    },
    url: {
      type: String,
      required: false,
      default: null,
    },
    showTag: {
      type: Boolean,
      required: false,
      default: true,
    },
    articleList: {
      type: Boolean,
      required: false,
      default: false,
    },
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
    categoryTag: {
      type: Boolean,
      required: false,
      default: false,
    },
    nav: {
      type: Boolean,
      required: false,
      default: false,
    },
    tagLink: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    categorySlug() {
      return this.category.uid
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 1.25rem;
  border-radius: 0.75rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  box-shadow: $c-elevation-1;
  color: $c-white;
  cursor: pointer;
  aspect-ratio: 1 / 1;

  &__overlay {
    border-radius: 0.75rem;
    background: linear-gradient(180deg, rgba(30, 25, 50, 0) 20.24%, rgba(30, 25, 50, 0.9) 77.28%);
  }

  &__link,
  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__link {
    z-index: 2;
  }

  .tag--top {
    position: relative;
    z-index: 4;
  }

  &__content {
    display: flex;
    z-index: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 0.5rem;

    .date {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: -0.25rem;
        left: 0;
        width: 1.5rem;
        height: 0.0625rem;
        background: $c-neutral-050;
      }
    }

    .summary {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &--large {
    aspect-ratio: 1 / 1.45;

    .date::after {
      content: '';
      position: absolute;
      top: -0.25rem;
      left: 0;
      width: 1.5rem;
      height: 0.0625rem;
      background: $c-neutral-050;
    }
  }

  &--clickable-tag {
    justify-content: space-between !important;
  }

  .has-lazy-background__background {
    z-index: 0;
  }
}
</style>
