<template>
  <div class="blog-card has-lazy-background">
    <div class="blog-card__overlay" />

    <NuxtLink
      class="blog-card__link"
      :to="localePath(article?.url)"
      :aria-label="article?.data?.title"
    />

    <Tag
      :label="article?.data?.category?.uid?.replaceAll('-', ' ')"
      :to="localePath(tagLink)"
      icon="description"
      small
      theme="primary"
    />

    <div class="blog-card__content">
      <p class="f-300 s-m-0 title f-size-24 f-size-24--responsive">
        {{ article?.data?.title }}
      </p>

      <Icon name="east" size="1.25rem" />
    </div>

    <picture class="has-lazy-background__background s-br-12">
      <img
        :alt="article?.data?.title"
        decoding="async"
        fetchpriority="low"
        loading="lazy"
        :src="article?.data?.image?.url"
      />
    </picture>
  </div>
</template>

<script>
// Components
import Icon from '@/components/Icon.vue'
import Tag from '@/components/Tag.vue'

export default {
  name: 'HomeBlogCard',
  components: {
    Icon,
    Tag,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.blog-card {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.25rem;
  border-radius: 0.75rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  box-shadow: $c-elevation-1;
  color: $c-white;
  aspect-ratio: 1 / 1;

  &__link,
  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__link {
    z-index: 2;
    cursor: pointer;
  }

  &__overlay {
    border-radius: 0.75rem;
    background: linear-gradient(180deg, rgba(30, 25, 50, 0) 20.24%, rgba(30, 25, 50, 0.5) 77.28%);
  }

  &__content {
    display: flex;
    z-index: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 0.5rem;
    width: 100%;

    :deep(.icon) {
      margin-left: auto;
    }
  }
}
</style>
