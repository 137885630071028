<template>
  <section class="astara-benefits-cards">
    <div class="container container--pv">
      <header class="s-mb-32">
        <p class="section-title">
          {{ $t('pages.home.astara_benefits_cards.title') }}
        </p>
      </header>

      <div
        ref="slider"
        class="swiper swiper--default-style swiper--default-style--center-pagination-when-navigation"
      >
        <div class="swiper-wrapper">
          <div
            v-for="i in 4"
            :key="`home_astara_benefits_cards_${i}`"
            class="swiper-slide card c-elevation-2"
          >
            <header class="s-pb-32">
              <div
                class="container-flex container-flex--align-start container-flex--justify-space-between container-flex--no-wrap s-gap-16"
              >
                <p class="f-500 f-size-24">
                  {{ $t(`pages.home.astara_benefits_cards.cards.${i}.title`) }}
                </p>

                <Icon
                  :name="$t(`pages.home.astara_benefits_cards.cards.${i}.icon`)"
                  size="2.5rem"
                />
              </div>
            </header>

            <p class="f-size-18 f-size-18--responsive s-pt-8 f-500">
              {{ $t(`pages.home.astara_benefits_cards.cards.${i}.description`) }}
            </p>
          </div>
        </div>

        <div class="swiper-pagination" />
        <div class="swiper-button-prev">
          <Icon name="chevron_left" />
        </div>
        <div class="swiper-button-next">
          <Icon name="chevron_right" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// Dependencies
import { Swiper, Navigation, Pagination } from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'

// Components
import Icon from '@/components/Icon.vue'

export default {
  name: 'HomeAstaraBenefitsCards',
  components: {
    Icon,
  },
  data() {
    return {
      sliderObject: null,
    }
  },
  mounted() {
    // Setup Swiper
    this.sliderObject = new Swiper(this.$refs.slider, ARTICLES_SWIPER_CONFIG)
  },
}

const ARTICLES_SWIPER_CONFIG = {
  modules: [Navigation, Pagination],
  lazy: true,
  lazyPreloadPrevNext: 1,
  loop: false,
  speed: 600,
  slidesPerView: 1,
  spaceBetween: 20,
  preventClicks: true,
  preventInteractionOnTransition: true,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: false,
    dynamicBullets: true,
    dynamicMainBullets: 1,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    // When window width is >= X px
    768: {
      lazyPreloadPrevNext: 1,
      slidesPerView: 2,
      spaceBetween: 20,
    },
    1024: {
      lazyPreloadPrevNext: 2,
      slidesPerView: 3,
      spaceBetween: 24,
      allowTouchMove: false,
      preventInteractionOnTransition: false,
      longSwipes: false,
    },
    1280: {
      lazyPreloadPrevNext: 2,
      slidesPerView: 4,
      spaceBetween: 32,
      allowTouchMove: false,
      preventInteractionOnTransition: false,
      longSwipes: false,
    },
    1440: {
      lazy: false,
      slidesPerView: 4,
      spaceBetween: 40,
      allowTouchMove: false,
      preventInteractionOnTransition: false,
      longSwipes: false,
    },
  },
}
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}

.astara-benefits-cards {
  overflow: hidden;

  .swiper {
    overflow: visible;

    .swiper-wrapper {
      align-items: stretch;
    }

    .swiper-slide {
      height: auto;
    }

    @include size-xl-up {
      .swiper-button-prev,
      .swiper-button-next,
      .swiper-pagination {
        display: none;
      }
    }

    @include size-m {
      .swiper-button-prev,
      .swiper-button-next {
        display: none;
      }
    }
  }
}

.card {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 2rem;
  border-radius: 0.5rem;
  background-size: cover;
  aspect-ratio: 1 / 1;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
  }

  header {
    position: relative;
    gap: 0.75rem;

    > div {
      min-height: 3.5rem;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 3.5rem;
      height: 0.125rem;
      background-color: $text-primary;
    }
  }

  &:nth-child(1) {
    background-color: $c-primary-800;
    color: $c-white;

    header::after {
      background-color: $c-white;
    }

    &::before {
      background-image: url('/textures/light/square-1.svg');
    }
  }

  &:nth-child(2) {
    background-color: $c-neutral-100;

    &::before {
      background-image: url('/textures/dark/square-2.svg');
    }
  }

  &:nth-child(3) {
    background-color: $c-support-01-500;

    &::before {
      background-image: url('/textures/dark/square-3.svg');
    }
  }

  &:nth-child(4) {
    background-color: $c-support-02-500;

    &::before {
      background-image: url('/textures/dark/square-4.svg');
    }
  }

  @include size-xl {
    > p,
    > div > p:not(:first-child) {
      font-size: 1rem;
      line-height: 1.25rem;
    }
  }

  @include size-l {
    header {
      :deep(.icon) {
        font-size: 1.5rem !important;
      }

      p {
        font-size: 1.125rem;
        line-height: 1.5rem;
      }
    }

    > p,
    > div > p:not(:first-child) {
      font-size: 0.875rem;
    }
  }
}
</style>
