<template>
  <picture :class="xClass">
    <!-- Desktop -->
    <source
      v-if="desktop1"
      media="(min-width: 1440px)"
      type="image/webp"
      :srcset="`/images/${path}/desktop-1.webp 1x, /images/${path}/desktop-1@2x.webp 2x`"
    />
    <source
      v-if="desktop2"
      media="(min-width: 1280px)"
      type="image/webp"
      :srcset="`/images/${path}/desktop-2.webp 1x, /images/${path}/desktop-2@2x.webp 2x`"
    />

    <!-- Tablet -->
    <source
      v-if="tablet1"
      media="(min-width: 1024px)"
      type="image/webp"
      :srcset="`/images/${path}/tablet-1.webp 1x, /images/${path}/tablet-1@2x.webp 2x`"
    />
    <source
      v-if="tablet2"
      media="(min-width: 768px)"
      type="image/webp"
      :srcset="`/images/${path}/tablet-2.webp 1x, /images/${path}/tablet-2@2x.webp 2x`"
    />

    <!-- Mobile -->
    <source
      type="image/webp"
      :srcset="`/images/${path}/mobile.webp 1x, /images/${path}/mobile@2x.webp 2x`"
    />

    <!-- Imagen principal -->
    <img
      :alt="alt"
      :decoding="loadAsync ? 'async' : undefined"
      :loading="loadLazy ? 'lazy' : undefined"
    />
  </picture>
</template>

<script>
export default {
  name: 'PartialsBackground',
  props: {
    path: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
    xClass: {
      type: String,
      required: false,
      default: '',
    },
    loadAsync: {
      type: Boolean,
      required: false,
      default: true,
    },
    loadLazy: {
      type: Boolean,
      required: false,
      default: true,
    },
    desktop1: {
      type: Boolean,
      required: false,
      default: true,
    },
    desktop2: {
      type: Boolean,
      required: false,
      default: true,
    },
    tablet1: {
      type: Boolean,
      required: false,
      default: true,
    },
    tablet2: {
      type: Boolean,
      required: false,
      default: true,
    },
    mobile: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
}
</script>
