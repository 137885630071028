<template>
  <div>
    <div class="make-model-form s-p-def s-br-8 c-elevation-2 c-background-primary">
      <Loader absolute :loading="loading" />

      <header>
        <p class="f-size-18 s-m-0">
          {{ $t('pages.home.make_model_form.title')[0] }}
          <span class="f-700">
            {{ $t('pages.home.make_model_form.title')[1] }}
          </span>
        </p>

        <div
          class="container-flex container-flex--align-stretch s-gap-16 make-model-form__vehicle-types s-mt-20 s-mb-20"
        >
          <button
            v-for="vehicleType of ['car', 'motorbike']"
            :key="`${vehicleType}_option`"
            :class="[
              'make-model-form__vehicle-types__option',
              'cursor-pointer',
              {
                'make-model-form__vehicle-types__option--selected':
                  selectedVehicleType === vehicleType,
              },
            ]"
            :data-cy="`make-model-form-${vehicleType}-option`"
            @click="
              () =>
                selectedVehicleType !== vehicleType ? onSelectVehicleType(vehicleType) : undefined
            "
          >
            <span class="f-500 f-size-14 f-uppercase">
              {{ $t(`global.vehicle_types.${vehicleType}`)[1] }}
            </span>
          </button>
        </div>
      </header>

      <form>
        <div
          class="pseudo-selector"
          data-cy="make-model-form-make-select"
          @click="() => onSearchBy('make')"
        >
          <Select
            :disabled="loading"
            :label="$t('pages.home.make_model_form.form.labels.make_slug')"
            :placeholder="makePlaceholder"
          />
        </div>

        <div
          class="pseudo-selector"
          data-cy="make-model-form-model-select"
          @click="() => onSearchBy('model')"
        >
          <Select
            :disabled="!makeSelected || loading"
            :label="$t('pages.home.make_model_form.form.labels.model_slug')"
            :placeholder="modelPlaceholder"
          />
        </div>

        <Button
          :disabled="loading || !makeSelected"
          :label="$t('pages.home.make_model_form.form.submit')"
          data-cy="make-model-form-submit"
          icon-right="search"
          size="large"
          type-theme="primary-light"
          @click="() => onSubmit()"
        />
      </form>

      <footer>
        <p class="f-size-18 s-mt-0 s-mb-20">{{ $t('pages.home.make_model_form.footer_title') }}</p>

        <div class="container-flex s-gap-12">
          <NuxtLink :to="localePath('astara-selection')" class="f-as-link">
            <span>{{ $t('global.categories_extended.move')[1] }}</span>
          </NuxtLink>

          <NuxtLink :to="localePath('motorbikes')" class="f-as-link">
            <span>{{ $t('global.categories_extended.motorbike')[1] }}</span>
          </NuxtLink>

          <NuxtLink :to="localePath('cars-vo')" class="f-as-link">
            <span>{{ $t('global.categories_extended.vo')[1] }}</span>
          </NuxtLink>

          <NuxtLink :to="localePath('cars-km0')" class="f-as-link">
            <span>{{ $t('global.categories_extended.km0')[1] }}</span>
          </NuxtLink>

          <NuxtLink :to="localePath('cars-vn')" class="f-as-link">
            <span>{{ $t('global.categories_extended.vn')[1] }}</span>
          </NuxtLink>
        </div>
      </footer>
    </div>

    <ModalsMakeModelBrowser
      v-if="makeOptions?.nodes || modelOptions?.nodes"
      :open="showMakeModelModal"
      home
      :search-by="searchBy"
      :vehicle-type="selectedVehicleType"
      :title="modalTitle"
      :data="searchBy == 'make' ? makeOptions?.nodes : modelOptions?.nodes"
      :models-make="makeSelected"
      @selected-make="(value) => fetchModels(value)"
      @selected-model="(value) => onSelectModel(value)"
      @close="() => (showMakeModelModal = false)"
    />
  </div>
</template>

<script>
// Queries
import {
  makes as makesQuery,
  models as modelsQuery,
  modelsByMake,
} from '@/queries/makeModelBrowser'

// Components
import ModalsMakeModelBrowser from '@/components/modals/MakeModelBrowser.vue'
import Button from '@/components/Button.vue'
import Loader from '@/components/Loader.vue'
import Select from '@/components/Select.vue'

export default {
  name: 'HomeMakeModelForm',
  components: {
    ModalsMakeModelBrowser,
    Button,
    Loader,
    Select,
  },
  data() {
    return {
      loading: true,
      makeOptions: [],
      makeSelected: undefined,
      modelOptions: [],
      modelSelected: undefined,
      searchBy: 'make',
      selectedVehicleType: 'car',
      showMakeModelModal: false,
      formState: {
        makeSlug: undefined,
        modelSlug: undefined,
      },
    }
  },
  computed: {
    makePlaceholder() {
      let palceholder

      if (!this.makeSelected) {
        palceholder = `${this.makeCount} ${this.$t('global.make')[this.makeCount === 1 ? 0 : 1]}`
      } else {
        palceholder = this.makeSelected?.name
      }

      return palceholder?.toUpperCase()
    },
    modelPlaceholder() {
      return `${this.modelCount} ${this.$t('global.model')[this.modelCount === 1 ? 0 : 1]}`
    },
    makeCount() {
      return this.makeOptions?.nodesCount || 0
    },
    modelCount() {
      return this.modelOptions?.nodesCount || 0
    },
    modalTitle() {
      if (this.searchBy === 'make') {
        return this.$t('pages.home.make_model_form.modal.make_title')
      } else {
        return this.$t('pages.home.make_model_form.modal.model_title', this.modelCount, {
          make: this.makeSelected?.name,
          count: this.modelCount,
        })
      }
    },
  },
  methods: {
    onCloseModal() {
      this.showMakeModelModal = false
    },
    onSelectVehicleType(vehicleType) {
      // Reset data
      this.makeOptions = []
      this.makeSelected = undefined
      this.modelOptions = []
      this.modelSelected = undefined
      this.searchBy = 'make'
      this.selectedVehicleType = vehicleType

      // Fetch data
      this.fetchMakes()
      this.fetchModels()
    },
    onSelectModel(model) {
      this.modelSelected = model
      this.modelRedirection()
    },
    makeRedirection() {
      try {
        this.loading = true

        let path
        if (this.selectedVehicleType === 'motorbike') {
          path = this.localePath({
            name: 'motorbikes',
            params: {
              facet1: this.makeSelected?.slug,
            },
          })
        } else {
          path = this.localePath({
            name: 'car-make',
            params: {
              make: this.makeSelected?.slug,
            },
          })
        }

        if (path) {
          this.$router.push(path)
        } else {
          throw new Error('Avoided redundant navigation to current location.')
        }
      } catch (err) {
        this.$logger('Error redirecting to the expected make page', err)

        this.makeSelected = undefined
        setTimeout(() => (this.loading = false), 500)
      }
    },
    modelRedirection() {
      try {
        this.loading = true

        let path
        if (this.selectedVehicleType === 'motorbike') {
          if (this.modelSelected?.availableUnits === 1) {
            path = this.localePath({
              name: 'motorbike-make-model-version',
              params: {
                make: this.modelSelected?.make?.slug,
                model: this.modelSelected?.slug,
                version: this.modelSelected?.version?.slug,
              },
            })
          } else {
            path = this.localePath({
              name: 'motorbikes',
              params: {
                facet1: this.modelSelected?.make?.slug,
                facet2: this.modelSelected?.slug.replace(/-\d{4}(?=\s*$)/, ''),
              },
            })
          }
        } else if (this.modelSelected?.availableUnits === 1) {
          if (this.modelSelected?.vnCount.length > 0) {
            path = this.localePath({
              name: 'car-vn-make-model',
              params: {
                make: this.modelSelected?.make?.slug,
                model: this.modelSelected?.slug,
              },
            })
          } else {
            path = this.localePath({
              name: `car-${
                this.modelSelected?.voCount.length > 0 ? 'vo' : 'km0'
              }-make-model-version-config-vehicle_uid`,
              params: {
                make: this.modelSelected?.make?.slug,
                model: this.modelSelected?.slug,
                version: this.modelSelected?.vehicles[0]?.version?.slug,
                config: this.modelSelected?.vehicles[0]?.vehicleConfig?.slug,
                vehicle_uid: this.modelSelected?.vehicles[0].uid,
              },
            })
          }
        } else {
          path = this.localePath({
            name: 'car-make-model',
            params: {
              make: this.modelSelected?.make?.slug,
              model: this.modelSelected?.slug.replace(/-\d{4}(?=\s*$)/, ''),
            },
          })
        }

        if (path) {
          this.$router.push(path)
        } else {
          throw new Error('Avoided redundant navigation to current location.')
        }
      } catch (err) {
        this.$logger('Error redirecting to the expected model/models page', err)

        this.modelSelected = undefined
        setTimeout(() => (this.loading = false), 500)
      }
    },
    onSubmit() {
      if (this.modelSelected) {
        this.modelRedirection()
      } else if (this.makeSelected) {
        this.makeRedirection()
      }
    },
    async fetchMakes() {
      const { app } = this.$nuxt.context

      try {
        this.loading = true

        const client = app.apolloProvider.defaultClient

        this.makeOptions = await client
          .query({
            query: makesQuery,
            variables: {
              vehicleTypeIn: this.selectedVehicleType === 'car' ? ['car', 'van'] : 'motorbike',
            },
          })
          .then(({ data }) => {
            return data?.makes || []
          })
      } catch (err) {
        this.$logger('Error fetching makes on MakeModelForm component', err)

        this.makeOptions = []
      } finally {
        setTimeout(() => (this.loading = false), 500)
      }
    },
    async fetchModels(make = undefined) {
      const { app } = this.$nuxt.context

      try {
        this.loading = true

        const client = app.apolloProvider.defaultClient

        const variables = {
          vehicleTypeIn: this.selectedVehicleType === 'car' ? ['car', 'van'] : 'motorbike',
        }

        if (make) {
          this.makeSelected = make
          variables.makeSlugLike = make?.slug
        }

        this.modelOptions = await client
          .query({
            query: make ? modelsByMake : modelsQuery,
            variables,
          })
          .then(({ data }) => {
            return data?.models || []
          })
      } catch (err) {
        this.$logger('Error fetching makes on MakeModelForm component', err)

        this.modelOptions = []
      } finally {
        setTimeout(() => (this.loading = false), 500)
      }
    },
    onSearchBy(value) {
      if (value === 'model' && !this.makeSelected) return

      this.searchBy = value
      this.showMakeModelModal = true
    },
  },
  mounted() {
    setTimeout(() => {
      this.fetchMakes()
      this.fetchModels()
    }, 0)
  },
}
</script>

<style lang="scss" scoped>
.make-model-form {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  min-height: 29.125rem;
  padding: var(--s-gap);

  &__vehicle-types__option {
    all: unset;
    display: block;
    padding-bottom: 0.25rem;
    border-bottom: 0.125rem solid transparent;
    cursor: pointer;

    &--selected {
      border-color: $c-support-01-500;
    }
  }

  form {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    margin-bottom: auto;
    justify-items: stretch;

    @include size-m-up {
      grid-template-columns: 1fr 1fr;
    }

    :deep(.button) {
      justify-content: space-between;
    }

    .pseudo-selector:deep(.select-wrapper) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      margin: 0;

      select {
        pointer-events: none !important;
      }

      .select-wrapper__feedback {
        display: none;
      }
    }
  }

  footer {
    padding-top: 2.5rem;

    @include size-xl-up {
      padding-top: 5rem;
    }
  }
}
</style>
