<template>
  <section class="buying-power has-lazy-background">
    <div class="container container--pv">
      <div class="buying-power__content">
        <slot name="content">
          <component :is="asMainSection ? 'h1' : 'h2'" class="f-gradient-title">
            {{ $t('modules.buying_power.title') }}
          </component>
          <component :is="asMainSection ? 'h2' : 'p'" class="buying-power__content__subtitle">
            {{ $t('modules.buying_power.subtitle') }}
          </component>

          <div class="buying-power__content__main">
            <p>
              {{ $t('modules.buying_power.content')[0] }}
            </p>

            <p>
              {{ $t('modules.buying_power.content')[1] }}
            </p>
          </div>
        </slot>
      </div>
      <div>
        <BuyingPowerFormPartial />
      </div>
    </div>

    <PartialsBackground
      :load-lazy="true"
      :alt="$t('modules.buying_power.alt')"
      :load-async="true"
      path="modules/buying-power/background"
      x-class="has-lazy-background__background"
    />
  </section>
</template>

<script>
// Components
import BuyingPowerFormPartial from '@/components/partials/BuyingPowerForm.vue'
import PartialsBackground from '@/components/partials/Background.vue'

export default {
  name: 'ModuleBuyingPower',
  components: {
    BuyingPowerFormPartial,
    PartialsBackground,
  },
  props: {
    asMainSection: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
p:not(.section-title) {
  margin: 0;

  & + p {
    margin-top: 1.4em;
  }
}

.buying-power {
  position: relative;
  scroll-margin-top: var(--navbar-height);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(113deg, rgba(0, 0, 0, 0.6) 18.65%, rgba(0, 0, 0, 0) 103.81%);
  }
}

.container {
  position: relative;
  z-index: 1;
  gap: 2.5rem;

  @include size-s-up {
    max-width: 32.5rem;
  }

  @include size-l-up {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 75rem !important;
    max-width: 100%;

    > div {
      width: calc(50% - 1.125rem);
    }

    :deep(.buying-power__form-wrapper) {
      max-width: 33.75rem;
      margin-left: auto;
    }
  }
}

.buying-power__content {
  padding-bottom: 2.5rem;
  color: $c-white;
  text-align: center;

  &__subtitle {
    font-size: 1.5rem;
    font-weight: 500;
  }

  &__main {
    p {
      font-size: 1.125rem;
      line-height: 1.5rem;

      & + p {
        margin-top: 0;
      }
    }
  }

  @include size-l-up {
    text-align: left;

    &__subtitle {
      margin: 2rem 0 1rem !important;
    }

    > * {
      max-width: 26rem;
    }
  }

  @include size-m {
    p {
      line-height: 1.5rem;
    }

    &__subtitle {
      margin: 2rem 0 0.75rem !important;
      font-size: 1.375rem;
      line-height: 1.5rem;
    }
  }

  @include size-s {
    &__subtitle {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }

    &__main p {
      font-size: 1rem;
      line-height: 1.25rem;
    }
  }
}
</style>
