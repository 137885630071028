var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'card',
    'has-lazy-background',
    {
      'card--large': _vm.large,
      'card--clickable-tag': (_vm.articleList || _vm.nav) && _vm.showTag,
    },
  ]},[_c('picture',{staticClass:"has-lazy-background__background s-br-12"},[_c('img',{attrs:{"alt":_vm.title,"decoding":"async","fetchpriority":"low","loading":"lazy","src":_vm.image}})]),_vm._v(" "),_c('div',{staticClass:"card__overlay"}),_vm._v(" "),(_vm.url)?_c('NuxtLink',{staticClass:"card__link",attrs:{"to":_vm.localePath(_vm.url)}}):_vm._e(),_vm._v(" "),(_vm.showTag && !_vm.categoryTag)?_c('Tag',{staticClass:"tag tag--top",attrs:{"label":`#${_vm.category?.data?.categoryname}`,"theme":_vm.large ? 'base-light' : 'primary',"to":_vm.localePath(_vm.tagLink),"small":""}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"card__content"},[(_vm.articleList)?_c('p',{staticClass:"date f-size-10 f-700 s-m-0"},[_vm._v("\n      "+_vm._s(_vm.formatDate(_vm.date))+"\n    ")]):_vm._e(),_vm._v(" "),_c('p',{class:[
        'f-500',
        's-m-0',
        'title',
        {
          'f-size-18': _vm.nav,
          'f-size-24 f-size-24--responsive': !_vm.nav,
        },
      ]},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]),_vm._v(" "),(_vm.nav)?_c('p',{staticClass:"date f-size-10 f-700 s-m-0"},[_vm._v("\n      "+_vm._s(_vm.formatDate(_vm.date))+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.categoryTag)?_c('Tag',{staticClass:"tag",attrs:{"label":`#${_vm.category.data.categoryname}`,"small":"","theme":"base-light"}}):_vm._e(),_vm._v(" "),(_vm.summary)?_c('p',{staticClass:"summary f-size-14 s-m-0"},[_vm._v(_vm._s(_vm.summary))]):_vm._e(),_vm._v(" "),(_vm.large)?_c('p',{staticClass:"date f-500 f-size-14 s-m-0"},[_vm._v("\n      "+_vm._s(_vm.large ? _vm.formatDateAlt(_vm.date) : _vm.formatDate(_vm.date))+"\n    ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }