<template>
  <div
    data-cy="catalog-card-simplified"
    :class="[
      'catalog-card',
      'catalog-card-simplified',
      `catalog-card-simplified--${vehicleType.toLowerCase()}`,
      `catalog-card-simplified--${category.toLowerCase()}`,
      {
        'catalog-card--secondary': secondary,
        'catalog-card-simplified--out-of-stock': isOutOfStock,
        'catalog-card-simplified--sold-out': isSoldOut,
        'catalog-card-simplified--disabled': disabled || isSoldOut || isOutOfStock,
        'catalog-card--has-price-base': retailPrice,
      },
    ]"
  >
    <!-- Global link -->
    <NuxtLink
      v-if="vehicleUrl && !disabled && !isSoldOut && !isOutOfStock"
      class="catalog-card-simplified__link"
      :aria-label="$t(`vehicle.view_${isMotorbike ? 'moto' : 'car'}`)"
      :to="vehicleUrl"
      @click.native="() => pushGtmEvent()"
    />

    <!-- Header -->
    <header class="catalog-card-simplified__header">
      <ImageImgix
        :alt="heroAlt"
        :url="hero"
        :width="560"
        area="1240,698"
        class="catalog-card-simplified__header__hero"
      />
      <PartialsUseCaseFlag
        v-if="useCaseFlag"
        :type="useCaseFlag"
        class="catalog-card-simplified__header__flag"
      />
    </header>

    <!-- Main content -->
    <div class="catalog-card-simplified__main">
      <div class="catalog-card-simplified__main__vehicle">
        <div class="catalog-card-simplified__main__vehicle__top">
          <div class="name">
            <span v-if="name" class="make-model">
              {{ name }}
            </span>

            <span v-if="vehicleData?.versionName" class="version">
              {{ vehicleData.versionName }}
            </span>
          </div>

          <!-- Favourite toggle-button -->
          <PartialsFavourite
            :favourite-markable-id="favouriteMarkableId"
            :favourite-markable-type="favouriteMarkableType"
            :options="vehicleData"
            class="favourite"
          />
        </div>

        <span v-if="usedVehicleInfo && !retailPrice" class="used-vehicle-info">
          {{ usedVehicleInfo }}
        </span>
      </div>

      <div class="catalog-card-simplified__main__pricing">
        <span class="pricing">
          <span v-if="retailPrice" class="price price--base f-size-14 f-500 f-line-through">
            {{ retailPrice }}
          </span>
          <span class="bold highlighted price">{{ price }}</span>
        </span>

        <span class="f-size-14 installment-price f-highlighted" v-html="installmentPrice" />
      </div>
    </div>
  </div>
</template>

<script>
// Dependencies
import { mapGetters } from 'vuex'

// Helpers & mixins
import { toCurrency } from '@/helpers/currency'
import { normaliseData } from '@/helpers/vehicleDataNormalisation'

// Components
import PartialsFavourite from '@/components/partials/Favourite.vue'
import ImageImgix from '@/components/ImageImgix.vue'
import PartialsUseCaseFlag from '@/components/partials/UseCaseFlag.vue'

export default {
  name: 'CatalogCardSimplified',
  components: {
    PartialsFavourite,
    ImageImgix,
    PartialsUseCaseFlag,
  },
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
    category: {
      type: String,
      required: false,
      default: 'VN',
    },
    level: {
      type: String,
      required: false,
      default: undefined,
      validator(value) {
        return ['model', 'version', 'vehicle'].includes(value)
      },
    },
    eventName: {
      type: String,
      required: false,
      default: undefined,
    },
    listId: {
      type: String,
      required: false,
      default: undefined,
    },
    listName: {
      type: String,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    secondary: {
      type: Boolean,
      required: false,
      default: false,
    },
    gtmData: {
      type: Object,
      required: false,
      default: undefined,
    },
    datalayerData: {
      type: Object,
      required: false,
      default: undefined,
    },
    url: {
      type: String,
      required: false,
      default: undefined,
    },
    specs: {
      type: [Array, undefined],
      required: false,
      default: undefined,
    },
  },
  computed: {
    ...mapGetters({
      languageIso: 'getLanguageIso',
    }),
    lang() {
      return this.languageIso || 'es'
    },
    actualLevel() {
      if (this.level) {
        return this.level
      } else {
        return this.isMotorbike ? 'version' : this.isVn ? 'model' : 'vehicle'
      }
    },
    vehicleData() {
      return normaliseData(this.vehicle, this.category, this.actualLevel)
    },
    vehicleType() {
      try {
        let type = this.vehicleData?.vehicleType

        if (!type) {
          type = ['vn', 'km0', 'vo', 'move'].includes(this.category.toLowerCase())
            ? 'car'
            : 'motorbike'
        }

        return type
      } catch {
        return 'car'
      }
    },
    isMove() {
      return this.vehicleType.toLowerCase() === 'car' && this.category.toLowerCase() === 'move'
    },
    isVn() {
      return this.vehicleType.toLowerCase() === 'car' && this.category.toLowerCase() === 'vn'
    },
    isVo() {
      return this.vehicleType.toLowerCase() === 'car' && this.category.toLowerCase() === 'vo'
    },
    isKm0() {
      return this.vehicleType.toLowerCase() === 'car' && this.category.toLowerCase() === 'km0'
    },
    isMotorbike() {
      return ['moto', 'motorbike', 'motorcycle'].includes(this.vehicleType.toLowerCase())
    },
    isSoldOut() {
      return (this.isKm0 || this.isVo || this.isMove) && this.vehicleData?.publishableUnits === 0
    },
    isOutOfStock() {
      return (this.isVn || this.isMotorbike) && this.vehicleData?.publishableUnits === 0
    },
    isPromotedForBlackFriday() {
      return this.$isPromotedForBlackFriday(this.vehicleData.useCases)
    },
    isPromoted() {
      return !this.isVn && this.$isPromoted(this.vehicleData.useCases)
    },
    vehicleUrl() {
      try {
        if (this.url) {
          return this.url
        } else {
          return this.localePath(this.vehicleData.url)
        }
      } catch {
        return undefined
      }
    },
    hero() {
      try {
        return this.vehicleData?.hero || this.vehicleData?.heroBackUp
      } catch {
        return undefined
      }
    },
    heroAlt() {
      try {
        return [
          this.$t(`global.vehicle_types.${this.vehicleType?.toLowerCase()}`)[0],
          this.vehicleData?.makeName,
          this.vehicleData?.modelName,
          this.vehicleData?.versionName,
        ].join(' ')
      } catch {
        return undefined
      }
    },
    useCaseFlag() {
      if (this.hideFlags) return undefined

      const confirmUseCase = (valid, name) => {
        return valid && !this.hiddenFlags?.includes(name)
      }

      const checkUseCase = (name) => {
        return confirmUseCase(this.vehicleData.useCases?.includes(name), name)
      }

      if (confirmUseCase(this.isSoldOut, 'sold')) {
        return 'sold'
      } else if (confirmUseCase(this.isOutOfStock, 'out_of_stock')) {
        return 'out_of_stock'
      } else if (confirmUseCase(this.isPromotedForBlackFriday, 'black_friday')) {
        return 'black_friday'
      } else if (checkUseCase('summer_days')) {
        return 'summer_days'
      } else if (
        confirmUseCase(this.$isPromotedForShowroomPrive(this.vehicleData.useCases), 'showroomprive')
      ) {
        return 'showroomprive'
      } else if (confirmUseCase(this.isPromoted, 'on_offer')) {
        return 'on_offer'
      } else {
        return undefined
      }
    },
    name() {
      try {
        return [this.vehicleData.makeName, this.vehicleData.modelName].join(' ')
      } catch {
        return undefined
      }
    },
    usedVehicleInfo() {
      try {
        if (this.isVn || this.isMotorbike) return undefined

        const string = []

        let year, month

        const registrationDate = this.vehicleData.registrationDate

        if (registrationDate) {
          const registrationDateSplitted = registrationDate.split('-')
          year = registrationDateSplitted[0]
          month = parseInt(registrationDateSplitted[1]) - 1
        }

        if (this.isVo || this.isMove) {
          if (year) string.push(year)

          const kms = this.vehicleData?.kilometres
          if (kms) string.push(`${kms} km`)
        } else {
          const monthName = month ? this.$t(`global.months_number.${month}`) : undefined

          const substring = []
          if (monthName) substring.push(monthName)
          if (year) substring.push(year)

          string[0] = substring.join(' ')
        }

        return string.join(', ')
      } catch {
        return undefined
      }
    },
    installmentPrice() {
      try {
        const value = this.vehicleData.pricing.financing[0].installment_price

        if (!value) return '—'

        const amount = toCurrency({
          value: this.vehicleData.pricing.financing[0].installment_price,
          lang: this.lang,
        })

        return this.$t('global.from_price_per_month_html', { amount })
      } catch {
        return undefined
      }
    },
    financingPriceValue() {
      try {
        return this.vehicleData.pricing.financing[0].financing_price
      } catch {
        return undefined
      }
    },
    priceWithDiscountValue() {
      try {
        if (!this.vehicleData.discounts?.length > 0) return undefined

        const discount = this.vehicleData.discounts[0]
        const endDate = new Date(discount.endDate)
        const startDate = new Date(discount.startDate)
        const today = new Date()

        if (today > startDate && today < endDate) {
          const discountedPrice = this.vehicleData.pricing?.discounted_price

          if (discount.typeId === 'fixed_value') {
            return discountedPrice - discount.amountCents
          } else {
            return discountedPrice * discount.percentage
          }
        } else {
          return undefined
        }
      } catch {
        return undefined
      }
    },
    retailPrice() {
      try {
        const value = this.vehicleData.pricing.retail_price || 0
        const referencePrice = this.priceWithDiscountValue || this.financingPriceValue || 0

        if (value <= referencePrice) {
          return undefined
        }

        return toCurrency({
          value,
          lang: this.lang,
        })
      } catch {
        return undefined
      }
    },
    price() {
      try {
        const value = this.priceWithDiscountValue || this.financingPriceValue

        if (!value) {
          return undefined
        }

        return toCurrency({
          value,
          lang: this.lang,
        })
      } catch {
        return undefined
      }
    },
    favouriteMarkableId() {
      try {
        if (this.actualLevel) {
          switch (this.actualLevel) {
            case 'version':
              return this.vehicleData.versionSlug
            case 'vehicle':
              return this.vehicleData.manufacturerRef
            case 'model':
            default:
              return this.vehicleData.modelSlug
          }
        } else {
          switch (this.category.toLowerCase()) {
            case 'move':
            case 'vo':
            case 'km0':
              return this.vehicleData.manufacturerRef
            default:
              return this.vehicleUrl.split('/').slice(-1)[0]
          }
        }
      } catch {
        return undefined
      }
    },
    favouriteMarkableType() {
      try {
        return this.actualLevel.charAt(0).toUpperCase() + this.actualLevel.slice(1)
      } catch {
        return undefined
      }
    },
  },
  methods: {
    pushGtmEvent() {
      if (this.datalayerData && this.gtmData) {
        this.gtmEvent()
      } else if (this.vehicle) {
        this.autoGtmEvent()
      }
    },
    gtmEvent() {
      if (process?.browser && process?.env?.gtm_enabled) {
        this.$gtm.push(this.gtmData)
      }

      if (this.datalayerData) {
        this.$tracker.selectItem(
          this.datalayerData.list_id,
          this.datalayerData.list_name,
          this.datalayerData.item,
          {
            vehicleType: this.datalayerData.vehicleType,
            category: this.datalayerData.category,
          },
        )
      }
    },
    autoGtmEvent() {
      if (process?.browser && process?.env?.gtm_enabled) {
        // SEO
        let data
        switch (this.category.toLowerCase()) {
          case 'vn':
            data = {
              event: this.eventName,
              make: this.vehicleData?.makeName,
              model: this.vehicleData?.modelName,
            }
            break
          case 'km0':
          case 'vo':
          case 'move':
            data = {
              event: this.eventName,
              make: this.vehicleData?.makeName,
              model: this.vehicleData?.modelName,
              version: this.vehicleData?.versionName,
              config: this.vehicleData?.vehicleConfigSlug,
              vehicle_uid: this.vehicleData?.uid,
            }
            break
          case 'motorbike':
          case 'moto':
          case 'motorcycle':
          default:
            data = {
              event: this.eventName,
              make: this.vehicleData?.makeName,
              model: this.vehicleData?.modelName,
              version: this.vehicleData?.versionName,
            }
            break
        }

        this.$gtm.push(data)

        this.$tracker.selectItem(
          this.listId || this.listName?.replaceAll(' ', '-'),
          this.listName,
          this.vehicle,
          {
            category: this.isVn || this.isMotorbike ? 'new' : 'used',
            vehicleType: this.vehicleType,
          },
        )
      }
    },
  },
}
</script>

<stlye lang="scss" scoped>
.catalog-card-simplified {
  position: relative;
  border-radius: 0.25rem;
  background-color: $c-white;
  overflow: hidden;
  box-shadow: $c-elevation-1;
  aspect-ratio: 1 / 1;

  &--secondary {
    background-color: $c-neutral-100;
  }

  &--disabled .catalog-card-simplified__header :deep(img) {
    opacity: 0.25;
  }

  &:not(.catalog-card-simplified--disabled) {
    @include size-l-up {
      transition: box-shadow 0.3s ease;

      &:hover {
        box-shadow: $c-elevation-2;
      }
    }
  }

  p,
  .icon,
  img {
    pointer-events: none;
  }

  p {
    margin: 0;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  a + p,
  p + a,
  p + p,
  a + a {
    margin-top: 0.25rem;
  }

  .favourite {
    position: relative;
    z-index: 3;

    .sign-form {
      z-index: 9;
    }
  }

  :deep(.before-now-price) {
    display: inline-flex;
    gap: 0.2em;
  }

  &__link {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &__header {
    position: relative;
    aspect-ratio: 16 / 9;

    &__flag {
      position: absolute !important;
      z-index: 1;
      top: 0.5rem;
      left: 0;
    }
  }

  &__main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;
    aspect-ratio: 2.29 / 1;

    &__vehicle__top {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      gap: 0.5rem;
      width: 100%;

      .name {
        width: calc(100% - 1.25rem - 0.5rem);
      }

      .make-model,
      .version {
        display: block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .make-model {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.25rem;
      }

      .version {
        font-size: 1rem;
        line-height: 1.25rem;
      }

      .make-model + .version,
      & + .used-vehicle-info {
        margin-top: 0.25rem;
      }
    }

    .used-vehicle-info {
      min-height: 1.25rem;
      color: $c-primary-900;
      font-size: 0.875rem;
      line-height: 1.25rem;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    &__pricing {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: auto;
      padding-top: 0.5rem;

      .pricing {
        display: inline-flex;
        flex-direction: column;
      }
    }
  }

  footer {
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 0;
    justify-content: flex-end;
    padding: 0 1rem 1rem;
  }
}
</stlye>
